import React, { useEffect, useState } from "react";
import { dataValues } from "./dataValues";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, submitModal } from "../store/actions/modalSetup";
import { removeString } from "./commonFunctions";
import { useHistory, Link, Redirect } from "react-router-dom"
import { HIDE_MODAL, SUBMIT_MODAL, THROW_SUCCESS } from "../store/constants";
import { FlutterpaymentHooks } from "./Flutterwave";
import { invokeCoralPay } from "../store/actions/Coralpay";

export function FormStructure() {
  const dispatch = useDispatch();
  const history = useHistory();
  const verify = useSelector((state) => state.modalSet.verify);
  const transSuccess = useSelector((state) => state.trans.success);
  const selectValue = useSelector((state) => state.trans.productData);
  const modalSet = useSelector((state) => state.modalSet);
  const productType = useSelector(state => state.product.selectPro)
  const intentData = useSelector((state) => state.trans.paymentIntent);
  const getAgent = useSelector(state => state.agentUser.getAgentData)
  const invokePayment = useSelector(state => state.invokeCoralpay.invoke)
  const [redirctTo, setRedirctTo] = useState(false);
  const nonUserIntentDetails = useSelector((state) => state.trans.nonUserIntent);

  const {
    setState,
    setAccountName,
    setAmount,
    setTransRef,
    setEmail,
  } = FlutterpaymentHooks();

  const handleChange = (e, name) => {
    // console.log(name)
    // console.log(e.target.value)
    dispatch(submitModal(name, e.target.value));
    if (productType && productType.productId.description === "Cable") {
      selectValue.filter(allData => {
        if (allData.slug === e.target.value) {
          console.log(allData)
          localStorage.setItem("amount", allData.amount)
          dispatch(submitModal("amount", allData.amount));
        }
      })
    }
    if (productType.productId.description === "Data") {
      selectValue.filter(allData => {
        // console.log(allData)
        if (allData.slug === e.target.value) {
          // console.log(allData)
          dispatch(submitModal("amount", allData.amount));
        }
      })
    }
  };

  // useEffect(() => {
  //   const email = localStorage.getItem("email") ? localStorage.getItem("email") : ""
  //   const phoneNumber = localStorage.getItem("phoneNumber") ? localStorage.getItem("phoneNumber") : ""
  //   dispatch(submitModal("email", email));
  //   dispatch(submitModal("phoneNumber", phoneNumber));
  // }, [])


  let d = transSuccess
    ? "otherCableForms"
    : verify
      ? "cableForm"
      : "otherAirtimeForms";

  useEffect(() => {
    if (transSuccess) {
      dispatch({ type: THROW_SUCCESS, payload: "Successfully verified" });
      let val = removeString(transSuccess.data.dataObject);

      console.log(val)

      const payk = {
        email: val.responseData.customer.emailAddress,
        phoneNumber: val.responseData.customer.phoneNumber,
        accountNumber: transSuccess.account.accountNumber,
        // accountNumber: val.responseData.customer.meterNumber ? val.responseData.customer.meterNumber : val.responseData.customer.accountNumber,
        accountName: val.responseData.customer.customerName,
      };

      dispatch({ ...modalSet, type: SUBMIT_MODAL, payload: payk });
    }
  }, [transSuccess]);

  const getValue = (code) => {
    if (modalSet.submit) {
      if (modalSet.submit[code]) {
        // if
        // console.log("this is the code: " + code)
        return modalSet.submit[code];
      } else {
        return "";
      }
    }
    return "";
  };

  useEffect(() => {
    if (modalSet.submit.paymentGate) {
      if (modalSet.submit.paymentGate === "flutterwave") {
        if (modalSet.submit && nonUserIntentDetails && !getAgent) {
          setState(true);
          setAccountName(modalSet.submit.accountName);
          setAmount(nonUserIntentDetails.result.totalAmount);
          setTransRef(nonUserIntentDetails.transRef);
          setEmail(modalSet.submit.email);
          dispatch({ type: HIDE_MODAL })
        }
      } else {
        
      }
    }
  }, [modalSet.submit.paymentGate]);

  console.log(redirctTo)

  useEffect(() => {
    if (invokePayment) {
      dispatch(hideModal())
      setRedirctTo(true)
      localStorage.setItem("coralurl", invokePayment.data.payPageLink)
      // window.location.href = invokePayment.data.payPageLink
      // history.push(invokePayment.data.payPageLink)
    }
  }, [invokePayment])

  if (redirctTo) {
    window.open(invokePayment.data.payPageLink)
  }

  return (
    <div className="w-full">
      {/* {modalSet.submit.slug === "DSTV" && modalSet.submit.slug === "GOTV" && modalSet.submit.slug === "Startimes" && ( */}
      {transSuccess && productType.productId.productcategoryId.categoryname === "Cable TV" && productType.productId.billerCode !== "Startimes" && (
        <>
          {verify && (
            <div className="form-control-wrap" style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }}>
              <select
                onChange={(e) => handleChange(e, "productName")}
                className={`form-control-lg form-control`}
                style={{ borderRadius: "3px" }}
              >
                <option>Select bouquet</option>
                {selectValue.map((allData, i) => (
                  <option value={allData.slug} key={i}>
                    {allData.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      )}

      {productType.productId.productcategoryId.categoryname === "Network" && (
        <div className="form-control-wrap" style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }}>
          <select
            onChange={(e) => handleChange(e, "productName")}
            className={`form-control-lg form-control`}
            style={{ borderRadius: "3px" }}
          >
            <option>Select bouquet</option>
            {selectValue.map((allData, i) => (
              <option value={allData.slug} key={i}>
                {allData.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {dataValues[d].map((allData, i) => (
        // <FormGroup key={i}>
        <div
          className="form-control-wrap"
          key={i}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <input
            type={allData.type}
            id={allData.code}
            name={allData.code}
            value={transSuccess && transSuccess.account.extras === "Startimes" && allData.code === "amount" ? localStorage.getItem("amount") : getValue(allData.code)}
            required={allData.required}
            disabled={
              allData.code === "amount"
                ? productType.productId.description === "Cable" || productType.productId.description === "Data"
                : false
            }
            placeholder={allData.placeHolder}
            onChange={(e) => handleChange(e, allData.code)}
            className={`form-control-lg form-control`}
          />

        </div>
        // </FormGroup>
      ))}



      {productType.productId.productcategoryId.categoryname === "Electricity" && (
        <div className="form-control-wrap" style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }}>
          <select
            onChange={(e) => handleChange(e, "productName")}
            className={`form-control-lg form-control`}
            style={{ borderRadius: "3px" }}
          >
            <option>Select bouquet</option>
            {selectValue.map((allData, i) => (
              <option value={allData.slug} key={i}>
                {allData.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {productType.productId.billerCode === "Startimes" && (
        <div className="form-control-wrap" style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }}>
          <select
            onChange={(e) => handleChange(e, "productName")}
            className={`form-control-lg form-control`}
            style={{ borderRadius: "3px" }}
          >
            <option>Select bouquet</option>
            {selectValue.map((allData, i) => (
              <option value={allData.slug} key={i}>
                {allData.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
}
