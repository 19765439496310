import axios from "axios";
import {
  AGENT_TXN,
  AGENT_TXN_ERR,
  FINAL_PAYMENT,
  FINAL_PAYMENT_ERROR,
  HIDESTATUS,
  NON_USER_PAYMENT_INTENT,
  NON_USER_PAYMENT_INTENT_ERROR,
  PAYMENT_INTENT,
  PAYMENT_INTENT_ERROR,
  PRODUCTNAME_DATA,
  PRODUCTNAME_ERROR,
  SHOWSTATUS,
  THROW_SUCCESS,
  VERIFY_FAILED,
  VERIFY_SUCCESS,
  WALLET_FINAL_ERROR,
  WALLET_INIT_PAYMENT_ERROR,
  WALLET_PAYMENT_FINAL,
  WALLET_PAYMENT_INIT,
} from "../constants";
import { secondTokenConfig } from "../constants/headers";
// import { FlutterpaymentHooks } from "../../utils/Flutterwave";
// import { useFlutterwave } from "flutterwave-react-v3";
import { apiConsumeMesage } from "../constants/messageSlice";
import { toast } from "react-toastify";
import { removeString } from "../../utils/commonFunctions";
import { finalAgentPayment } from "./finalPayment";
import { getUser } from "./userAction";
import { openAgentIntentModal } from "./modalSetup";
import CallPayment from "../../utils/CallPayment";

export const agentTransaction = (agentId) => (dispatch, getState) => {
  axios.get(`${process.env.REACT_APP_API}/api/agenttransactionlog/${agentId}`, secondTokenConfig(getState))
    .then(res => {
      dispatch({ type: AGENT_TXN, payload: res.data.data })
    })
    .catch(err => {
      dispatch({ type: AGENT_TXN_ERR, payload: err })
    })
}

export const userTransaction = (userId) => (dispatch, getState) => {
  axios.get(`${process.env.REACT_APP_API}/api/transaction/mobile/${userId}`, secondTokenConfig(getState))
    .then(res => {
      dispatch({ type: AGENT_TXN, payload: res.data })
    })
    .catch(err => {
      dispatch({ type: AGENT_TXN_ERR, payload: err })
    })
}

// export const verifySmartcardNumber = (valueData) => async (
//   dispatch,
//   getState
// ) => {
//   axios
//     .post(
//       `${process.env.REACT_APP_API}/api/product/merchant/customer-lookup`,
//       valueData,
//       secondTokenConfig(getState)
//     )
//     .then((res) => {
//       dispatch(apiConsumeMesage(res, "verify_success"));
//       // dispatch(openAgentIntentModal())
//       dispatch({
//         type: VERIFY_SUCCESS,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       console.log(err.response.data)
//       if(err.response.data.data.dataObject) {
//         const dataObject = removeString(err.response.data.data.dataObject);
//         console.log(dataObject)
//         toast.error(dataObject.message);
//       } else {
//         toast.error(err.response.data.message)
//       }

//       // dispatch(apiConsumeMesage(dataObject));
//       dispatch({
//         type: VERIFY_FAILED,
//         payload: err.response
//       });
//     });
// };

export const verifySmartcardNumber = (valueData) => async (
  dispatch,
  getState
) => {
  axios
    .post(
      `${process.env.REACT_APP_API}/api/product/merchant/customer-lookup`,
      valueData,
      {
        ...secondTokenConfig(getState),
        timeout: 60000 // 60 seconds timeout
      }
    )
    .then((res) => {
      dispatch(apiConsumeMesage(res, "verify_success"));
      // dispatch(openAgentIntentModal())
      dispatch({
        type: VERIFY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      if (err.response.data.data.dataObject) {
        const dataObject = removeString(err.response.data.data.dataObject);
        console.log(dataObject);
        toast.error(dataObject.message);
      } else {
        toast.error(err.response.data.message);
      }

      // dispatch(apiConsumeMesage(dataObject));
      dispatch({
        type: VERIFY_FAILED,
        payload: err.response,
      });
    });
};


export const PaymentIntent = (newValuesObj) => async (dispatch, getState) => {
  await axios
    .post(
      `${process.env.REACT_APP_API}/api/transaction/payment/intent`,
      newValuesObj,
      secondTokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: THROW_SUCCESS,
        payload: "Payment initialized Successfully",
      });
      dispatch({
        type: NON_USER_PAYMENT_INTENT,
        payload: res.data,
      });

      // console.log(getState().modalSet)
      // if(getState().modalSet.submit.paymentGate === "flutterwave") {
      //   alert("working here")
      //   return <CallPayment />
      // }
    })
    .catch((err) => {
      dispatch({
        type: NON_USER_PAYMENT_INTENT_ERROR,
        payload: err.response,
      });
    });
};

export const PaymentAgentIntent = (newValuesObj) => async (dispatch, getState) => {
  await axios
    .post(
      `${process.env.REACT_APP_API}/api/agentwallet/payment/mobile/intent`,
      newValuesObj,
      secondTokenConfig(getState)
    )
    .then((res) => {
      dispatch(openAgentIntentModal())
      dispatch({
        type: THROW_SUCCESS,
        payload: "Payment initialized Successfully",
      });
      dispatch({
        type: PAYMENT_INTENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PAYMENT_INTENT_ERROR,
        payload: err.response,
      });
    });
};

export const productNameData = (billerCode) => (dispatch, getState) => {
  axios
    .get(
      `${process.env.REACT_APP_API}/api/coralpay/biller/slug/${billerCode}`,
      secondTokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: PRODUCTNAME_DATA, payload: res.data.responseData });
    })
    .catch((err) => {
      dispatch({ type: PRODUCTNAME_ERROR, payload: err });
    });
};


export const UserWalletPaymentInit = (values) => async (dispatch, getState) => {
  await axios
    .post(
      `${process.env.REACT_APP_API}/api/wallettrans/payment/web/intent`,
      values,
      secondTokenConfig(getState),
      { timeout: 200 }
    )
    .then((res) => {
      dispatch(openAgentIntentModal())
      dispatch({
        type: WALLET_PAYMENT_INIT,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: WALLET_INIT_PAYMENT_ERROR,
        payload: err.response,
      });
    })
};

export const UserWalletPaymentFinal = (values) => async (dispatch, getState) => {
  await axios
    .post(
      `${process.env.REACT_APP_API}/api/wallettrans/payment/mobile/finalize`,
      values,
      secondTokenConfig(getState),
      { timeout: 200 }
    )
    .then((res) => {
      dispatch(getUser())
      console.log(res.data)
      dispatch({
        type: FINAL_PAYMENT,
        payload: res.data,
      })
    })
    .catch((err) => {
      toast.error(err.response.data.result.productResult.responseData.message)
      // console.log(err.response)
      dispatch({
        type: FINAL_PAYMENT_ERROR,
        payload: err.response,
      });
    })
};